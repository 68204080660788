<template>
  <div class="container">
    <div class="content">
      <div v-for="item in orgList" :key="item.id" class="card" @click="toHome(item.id)">
        {{ item.zhName }}
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from '@/api/api'

export default {
  name: 'Verification',
  data() {
    return {
      orgList: ''
    }
  },
  created() {
    const { data } = this.$route.params
    this.orgList = data
  },
  methods: {
    async toHome(organizationId) {
      const phone = sessionStorage.getItem('phone')
      const { code, data, message } = await getToken({ organizationId, phone })
      if (code === 0) {
        sessionStorage.setItem('token', data.accessToken)
        sessionStorage.setItem('orgId', organizationId)
        this.$router.push({ name: 'Home' })
      } else {
        console.log(message)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  & > .nav {
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    text-align: center;
    height: 3rem;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 3rem;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: .9rem .5rem .9rem .7rem;
      border-color: transparent;
      background-size: 1.2rem 2rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 .3rem 0 .8rem;
      top: 9px;
      left: 0px;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }
  & > .content {
    padding: 10rem 2rem 0;
    .tips {
      text-align: center;
      font-weight: 600;
      font-size: 1.125rem;
    }
    .card {
      text-align: center;
      margin-top: 3rem;
      padding: 1rem;
      border: 1px solid #ccc;
      box-shadow: 0 0 0.3rem #ccc;
      background-color: #fff;
      border-radius: 0.3rem;
    }
  }
}
</style>
